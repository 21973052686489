<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="search(1)">
            <el-form-item prop="basicTypeId" label="数据类型：">
                <el-select v-model="searchForm.basicTypeId" placeholder="请选择数据类型" clearable>
                    <el-option v-for="item in basicTypeOpt" :key="item.id" :label="item.archivesBasicType"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="basicName" label="输入搜索：">
                <el-input v-model.trim="searchForm.basicName" maxlength="10" placeholder="元数据名称（限10字）"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="required" label="是否必选：">
                <el-select placeholder="请选择" clearable v-model="searchForm.required" style="width: 100px">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('public_state')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
            <el-button class="f_r" type="primary" icon="el-icon-plus" size="small"
                       v-show="hasPermission('metadata:list:add')"
                       @click="addEdit(null, 'add')">新增
            </el-button>
        </el-form>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 296px)"
                    class="table" ref="multipleTable">
                <el-table-column prop="basicName" label="名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="archivesBasicType" label="数据类型" show-overflow-tooltip></el-table-column>
                <el-table-column prop="required" label="是否必选" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("public_state", scope.row.required ,'')}}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="120" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   v-show="hasPermission('metadata:list:delete')"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <ListForm ref="listForm" @refreshDataList="search()"></ListForm>
    </div>
</template>

<script>
    import ListForm from './listForm'

    export default {
        components: {
            ListForm
        },
        data() {
            return {
                searchForm: {
                    basicTypeId: '',
                    required: '',
                    basicName: '',
                    current: 1,
                    size: 10,
                },
                basicTypeOpt: [],
                loading: false,
                dataList: [],
                total: 0,
            }
        },
        mounted() {
            this.getArchives()
            this.search(1)
        },
        methods: {
            // 查询
            search(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                this.$axios(this.api.record.selectArchivesBasic, this.searchForm, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 获取数据类型
            getArchives() {
                this.$axios(this.api.record.getAllArchivesBasicType).then((res) => {
                    if (res.status) {
                        this.basicTypeOpt = res.data
                    }
                })
            },
            // 新增
            addEdit(row, method) {
                this.$refs.listForm.init(row, method)
            },
            // 删除
            del(id) {
                // 校验是否被使用
                this.$axios(this.api.record.checkRemoveById + '/' + id, {}, 'get').then(data => {
                    if (!data.status) {
                        const h = this.$createElement;
                        let type = data.msg == '001' ? '模板' : '选项'
                        this.$msgbox({
                            title: '提示',
                            message: h('p', null, [
                                h('p', {style: 'color: #000000; margin-bottom: 5px'}, data.data),
                                h('p', {style: 'color: #999999; font-size: 12px'}, '请去【元数据' + type + '管理】菜单中，删除该条元数据。')
                            ]),
                            showCancelButton: true,
                            confirmButtonText: '去解除使用',
                            cancelButtonText: '取消',
                        }).then(() => {
                            if (data.msg == '001') {
                                this.$router.push({path: '/record/metadata/templateList'})
                            } else {
                                this.$router.push({path: '/record/metadata/optionList'})
                            }
                        });
                    } else {
                        this.$confirm(`确定删除所选项吗?`, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.$axios(this.api.record.archivesRemoveById + '/' + id, {}, 'delete').then(data => {
                                if (data.status) {
                                    this.$message.success('删除成功')
                                    this.search()
                                } else {
                                    this.$message.error(data.msg)
                                }
                            })
                        })
                    }
                })
            },
            //重置
            reset() {
                this.$refs.searchForm.resetFields();
                this.search(1)
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped>

</style>
